import React from 'react';
import { useDeviceType } from '../../hooks/use-device-type';
import clsx from 'clsx';
import ReplyIcon from '@mui/icons-material/Reply';

export default function TrainingPage() {
	const deviceType = useDeviceType();

	return (
		<>
			<div
				className={clsx(
					'border-b-gray-200 border-[1px] rounded-[20px] py-8 shadow-xl w-[92%] my-6 mx-auto',
					'lg:w-[75%] lg-min-w-[500px] lg:px-10 lg:mx-auto'
				)}
			>
				<div
					className={clsx(
						'font-playfair-custom-bold text-4xl mb-4 text-center',
						'lg:text-5xl lg:text-center'
					)}
				>
					Jak mogę Ci <span className="text-rose-350">pomóc?</span>
				</div>

				<div
					className={clsx(
						'flex flex-col gap-2',
						'lg:flex lg:flex-row lg:gap-10'
					)}
				>
					<div className="flex gap-4 lg:gap-8 flex-col py-5 lg:py-3 px-4 lg:px-0 items-center">
						<img
							src="/contact/2.png"
							alt="1"
							className={clsx(
								'w-[64px] min-w-[96px] shadow-lg',
								'rounded-full lg:w-[128px] lg:min-w-[128px] border lg:transition-transform lg:shadow-none lg:hover:shadow-lg lg:hover:scale-105'
							)}
						/>
						<div className="flex flex-col">
							<div className="font-montserrat-custom-medium text-lg font-bold text-center mb-2 lg:mb-4">
								Poprawa kondycji i&nbsp;sylwetki
							</div>
							<div className="font-montserrat-custom-medium text-md text-center">
								Moje metody treningowe są bardzo skuteczne
								zarówno dla osób, które chcą zbudować siłę, jak
								i&nbsp;tych, którzy pragną wrócić do formy po
								długiej przerwie w&nbsp;aktywności fizycznej.
							</div>
						</div>
					</div>

					<div className="h-[1px] bg-gray-300 w-[95%] mx-auto rounded lg:hidden"></div>

					<div className="flex gap-4 lg:gap-8 flex-col py-5 lg:py-3 px-4 lg:px-0 items-center">
						<img
							src="/contact/1.png"
							alt="2"
							className={clsx(
								'w-[64px] min-w-[96px] shadow-lg',
								'rounded-full lg:w-[128px] lg:min-w-[128px] border lg:transition-transform lg:shadow-none lg:hover:shadow-lg lg:hover:scale-105'
							)}
						/>
						<div className="flex flex-col">
							<div className="font-montserrat-custom-medium text-lg font-bold text-center mb-2 lg:mb-4">
								Rozwiązanie problemów bólowych i&nbsp;wzrost
								sprawności
							</div>
							<div className="font-montserrat-custom-medium text-md text-center">
								Zapewniam kompleksowe wsparcie przy pokonywaniu
								bólu pleców, poprawie ogólnej sprawności oraz
								radzeniu sobie z&nbsp;ograniczeniami ruchowymi.
								Moim celem jest przywrócenie Ci pełni zdrowia
								i&nbsp;sprawności.
							</div>
						</div>
					</div>

					<div className="h-[1px] bg-gray-300 w-[95%] mx-auto rounded lg:hidden"></div>

					<div className="flex gap-4 lg:gap-8 flex-col py-5 lg:py-3 px-4 lg:px-0 items-center">
						<img
							src="/contact/3.png"
							alt="3"
							className={clsx(
								'w-[64px] min-w-[96px] shadow-lg',
								'rounded-full lg:w-[128px] lg:min-w-[128px] border lg:transition-transform lg:shadow-none lg:hover:shadow-lg lg:hover:scale-105'
							)}
						/>
						<div className="flex flex-col">
							<div className="font-montserrat-custom-medium text-lg font-bold text-center mb-2 lg:mb-4">
								Treningi pełne energii i&nbsp;motywacji
							</div>
							<div className="font-montserrat-custom-medium text-md text-center">
								Oferuję ćwiczenia, które są nie tylko efektywne,
								ale i&nbsp;przyjemne. Zadbam o&nbsp;to, aby
								każdy trening był interesujący
								i&nbsp;motywujący, dopasowany do Twoich
								indywidualnych preferencji.
							</div>
						</div>
					</div>

					<div className="h-[1px] bg-gray-300 w-[95%] mx-auto rounded lg:hidden"></div>

					<div className="flex gap-4 lg:gap-8 flex-col py-5 lg:py-3 px-4 lg:px-0 items-center">
						<img
							src="/contact/4.png"
							alt="4"
							className={clsx(
								'w-[64px] min-w-[96px] shadow-lg',
								'rounded-full lg:w-[128px] lg:min-w-[128px] border lg:transition-transform lg:shadow-none lg:hover:shadow-lg lg:hover:scale-105'
							)}
						/>
						<div className="flex flex-col">
							<div className="font-montserrat-custom-medium text-lg font-bold text-center mb-2 lg:mb-4">
								Pozbywanie się kompleksów i&nbsp;budowanie
								pewności siebie
							</div>
							<div className="font-montserrat-custom-medium text-md text-center">
								Moje programy treningowe są opracowane tak, abyś
								szybko zauważył zmiany w&nbsp;swojej sylwetce
								i&nbsp;samopoczuciu. Popracujemy razem nad Twoim
								pozytywnym postrzeganiem ciała
								i&nbsp;umocnieniem pewności siebie.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				className={clsx(
					'border-b-gray-200 border-[1px] rounded-[20px] py-4 shadow-xl w-[92%] my-6 mx-auto',
					'lg:w-[70%] lg-min-w-[500px] lg:px-10 lg:mx-auto'
				)}
			>
				<div
					className={clsx(
						deviceType === 'smartphone' ? 'mx-2' : '',
						deviceType === 'monitor'
							? 'flex flex-col mx-auto min-w-[400px] max-w-[860px]'
							: ''
					)}
				>
					<div
						className={clsx(
							'flex flex-col my-4 w-full',
							'lg:flex-row lg:gap-4 lg:justify-around'
						)}
					>
						<div className="lg:w-[40%]">
							<div
								className={clsx(
									'font-playfair-custom-bold font-bold mb-3 text-center text-[2rem]',
									'lg:ml-8 lg:text-[30px]'
								)}
							>
								<span className="text-rose-350">
									Trening personalny
								</span>
							</div>
							<div className="flex flex-col gap-3">
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Chcesz nauczyć się bezpiecznie oraz
										skutecznie trenować?
									</span>
								</div>
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Chcesz osiągnąć wymarzoną sylwetkę?
									</span>
								</div>
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Chcesz poprawić komfort codziennego
										życia?
									</span>
								</div>
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Chcesz zwiększyć swoją wytrzymałość i
										siłę?
									</span>
								</div>
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Szukasz motywacji i wsparcia w trakcie
										treningów?
									</span>
								</div>
							</div>
						</div>

						<div className="h-[1px] bg-gray-300 w-[95%] mx-auto rounded lg:hidden my-6"></div>

						<div className="lg:w-[40%]">
							<div
								className={clsx(
									'font-playfair-custom-bold font-bold mb-3 text-center text-[2rem]',
									'lg:ml-8 lg:text-[30px]'
								)}
							>
								<span className="text-rose-350">
									Trening medyczny
								</span>
							</div>
							<div className="flex flex-col gap-3">
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Chcesz bezpieczenie trenować pomimo wady
										postawy?
									</span>
								</div>
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Masz dosyć bólu?
									</span>
								</div>
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Chcesz wrócić do sprawności po kontuzji?
									</span>
								</div>
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Chcesz poprawić zakres ruchu i
										elastyczność?
									</span>
								</div>
								<div className="flex flex-row gap-3">
									<ReplyIcon className="rotate-180 text-rose-350" />
									<span className="font-montserrat-custom-medium text-[1.1rem]">
										Cenisz sobie indywidualne podejście i
										bezpieczeństwo pod okiem specjalisty?
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
