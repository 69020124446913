import * as React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Navigation } from './components';
import { MainPage } from 'pages/main-page';
import EBookPage from './pages/ebook/ebook';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Root() {
	const { pathname } = useLocation();

	return (
		<div>
			{pathname === "/ebook" ?
				<EBookPage />
				:
				<div>
					<Navigation />
					<div className="md:pt-[100px]">
						{pathname === '/' ? <MainPage /> : <Outlet />}
					</div>
				</div>}
			<ToastContainer
				position="top-center"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				style={{ zIndex: 9999 }}
			/>
		</div>
	);
}
