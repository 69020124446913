import { useState, useEffect } from 'react';

export const useDeviceType = () => {
	const [deviceType, setDeviceType] = useState<'monitor' | 'smartphone'>(
		'monitor'
	);

	useEffect(() => {
		const breakpoints = {
			sm: { min: 0, max: 640 },
			md: { min: 641, max: 768 },
			lg: { min: 769, max: 1024 },
			xl: { min: 1025, max: 1280 },
			xxl: { min: 1281, max: Infinity },
		};

		const detectDeviceType = () => {
			const screenWidth = window.innerWidth;

			if (screenWidth <= breakpoints.md.max) {
				return 'smartphone';
			} else {
				return 'monitor';
			}
		};

		const handleResize = () => {
			setDeviceType(detectDeviceType());
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return deviceType;
};
