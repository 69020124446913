export const BENEFITS = [
	{ benefitText: '60 minut treningu' },
	{ benefitText: 'indywidualne podejście' },
	{ benefitText: 'motywację i wsparcie na każdym etapie wrspółpracy' },
	{ benefitText: 'wiedzę, jak bezpieczenie trenować' },
	{ benefitText: 'skuteczne i urozmaicone ćwiczenia' },
	{ benefitText: 'długotrwałe efekty' },
	{
		benefitText:
			'monitorowanie postępów podczas comiesięcznych pomiarów ciała oraz analiza składu ciała na maszynie ' +
			'Tanita (poziom tkanki tłuszczowej, tkanki mięśniowej',
	},
	{ benefitText: 'poprawa komfortu codziennego życia' },
];

export const FAQ = [
	{
		question: 'Co zabrać ze sobą na pierwszy trening?',
		answers: [
			'Wygodny strój sportowy',
			'Obuwie na zmianę',
			'Kłódkę - do zamknięcia szafki',
			'Ręcznik - aby zachować higienę podczas korzystania z urządzeń na siłowni',
		],
	},
	{
		question: 'Czy istnieje możliwość treningu w zacisznym miejscu?',
		answers: [
			'TAK, taki rodzaj treningu jest możliwy, natomiast godziny są ograniczone ze względu na ewentualne ' +
				'rezerwacje salek na siłowni. Możemy umówić się w godzinach, w których salka na siłowni będzie wolna. ',
			'Z pewnością taki rodzaj treningu pozwoli na zapewnienie większego komfortu treningu.',
		],
	},
	{
		question: 'Jak długo trwa trening lub konsultacja?',
		answers: ['Trening jak i konsultacja przed trwa 60 minut.'],
	},
	{
		question: 'Jaka jest cena treningu?',
		answers: [
			'Cena treningu zależy od pakietu, jaki wybierzesz.',
			'Ofertę wraz z cennikiem znajdziesz w moim cenniku.', // TODO: add redirect to `offer` page in here
		],
	},
	{
		question: 'Czy w cenę pakietu wliczony jest karnet wstępu na siłownię?',
		answers: [
			'Nistety nie. Karnet lub jednorazowe wejście na siłownie musisz zakupić we wlasnym zakresie.',
		],
	},
];
