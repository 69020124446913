import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './index.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome-svg-core/styles.css';
import Root from './Root';
import AboutMePage from './pages/about-me/about-me';
import ContactPage from './pages/contact/contact';
import CalendarPage from './pages/calendar/calendar';
import { GlobalError } from './components';
import './fonts/fonts.css';
import OfferPage from './pages/offer/offer';
import FAQPage from './pages/faq/faq';
import TrainingPage from './pages/training/training';
import OpinionPage from './pages/opinions/opinions';
import EBookPage from './pages/ebook/ebook';

library.add(faEnvelope);

const router = createBrowserRouter([
	{
		path: '/',
		element: <Root />,
		errorElement: <GlobalError />,
		children: [
			{
				path: 'about-me',
				element: <AboutMePage />,
			},
			{
				path: 'contact',
				element: <ContactPage />,
			},
			{
				path: 'calendar',
				element: <CalendarPage />,
			},
			{
				path: 'offer',
				element: <OfferPage />,
			},
			{
				path: 'faq',
				element: <FAQPage />,
			},
			{
				path: 'training',
				element: <TrainingPage />,
			},
			{
				path: 'opinions',
				element: <OpinionPage />,
			},
			{
				path: 'ebook',
				element: <EBookPage />,
			},
		],
	},
]);

ReactDOM.createRoot(document.getElementById('root')).render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);
