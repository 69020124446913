import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import clsx from 'clsx';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { ToggleButton } from '@mui/material';
import { useDeviceType } from '../../hooks/use-device-type';

const alwaysContent = [
	'wyliczone makro i mikro kalorie',
	'po zakończonych wspólnych treningach - kontakt przez messangera w razie pytań lub wątpliwości',
	'po zakończonej współpracy możliwość comiesięcznej kontroli postępów oraz analiza składu ciała',
	'dodatkowe pliki edukacyjne',
];

const or = '« LUB »';

const slides = [
	{
		title: 'Pakiet na start',
		content: [
			'6 treningów personalnych',
			`rozpiska treningowa na 3 miesiące`,
			...alwaysContent,
		],
		price: '699 zł',
		priceForCouple: '1199 zł',
		special: true,
	},
	{
		title: 'Pakiet 8',
		content: [
			'( 1 miesiąc, 2 treningi w tygodniu )',
			'space',
			...alwaysContent,
		],
		price: '899 zł',
		priceForCouple: '1599 zł',
	},
	{
		title: 'Pakiet 12',
		content: [
			'( 1 miesiąc, 3 treningi w tygodniu )',
			'space',
			...alwaysContent,
		],
		price: '1299 zł',
		priceForCouple: '2399 zł',
	},
	{
		title: 'Pakiet 24',
		content: [
			'( 2 miesiące, 3 treningi w tygodniu )',
			or,
			'( 3 miesiące, 2 treningi w tygodniu )',
			'space',
			...alwaysContent,
		],
		price: '2499 zł',
		priceForCouple: '4699 zł',
	},
	{
		title: 'Pakiet 36',
		content: [
			'( 3 miesiące, 3 treningi w tygodniu )',
			or,
			'( 4 miesiące, 2 treningi w tygodniu )',
			'space',
			...alwaysContent,
		],
		price: '3499 zł',
		priceForCouple: '6999 zł',
	},
	{
		title: 'Konsultacja',
		content: [
			'Plan treningowy lub współpracę treningową poprzedza konsultacja, na której:',
			'space',
			'powiem Ci więcej o Twoich możliwościach ruchowych - jakie miejsca w Twoim ciele są słabsze i mocniejsze względem innych,',
			'ustalimy cele,',
			'ustalimy plan działania,',
			'przeanalizujemy Twój skład ciała,',
			'pokażę Ci jak wygląda trening personalny.',
		],
		price: '100 zł',
		priceForCouple: '80 zł',
		coupleSnag: 'od osoby',
	},
];

export default function OfferPage() {
	const sliderHeight = 550;
	const sliderMainHeight = sliderHeight + 40;
	const [currentSlide, setCurrentSlide] = useState(slides.length - 1);
	const [currentTraining, setCurrentTraining] = useState<
		'personal' | 'group'
	>('personal');
	const deviceType = useDeviceType();

	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: deviceType === 'monitor' ? 3 : 1,
		slidesToScroll: 1,
		initialSlide: deviceType === 'monitor' ? slides.length - 1 : 1,
		afterChange: (currentSlide: number) => setCurrentSlide(currentSlide),
	};

	const getNextSlide = () => {
		const nextSlide = currentSlide + 1;
		if (nextSlide > slides.length - 1) {
			return 0;
		}
		return nextSlide;
	};

	const getProperStyleToDisplayValue = (value: string) => {
		if (value === or) {
			return 'mt-[-3px] mb-[-5px]';
		}
		return '';
	};

	const getDisplayValue = (value: string) => {
		if (value === 'space') {
			return '';
		}
		if (value === or) {
			return value;
		}
		return (
			<>
				<span className="text-green-450">✔ </span>
				{value}
			</>
		);
	};

	return (
		<div>
			<div className="flex justify-center mt-8 lg:mt-10">
				<ToggleButtonGroup
					color="standard"
					value={currentTraining}
					exclusive
					onChange={(_, value) => {
						if (value) {
							setCurrentTraining(value);
						}
					}}
				>
					<ToggleButton value="personal">
						<span className="lg:px-4 font-montserrat-custom-medium font-bold text-rose-350">
							TRENING PERSONALNY
						</span>
					</ToggleButton>
					<ToggleButton value="group">
						<span className="lg:px-4 font-montserrat-custom-medium font-bold text-rose-350">
							TRENING DLA PAR
						</span>
					</ToggleButton>
				</ToggleButtonGroup>
			</div>
			<div className="flex justify-center items-center mt-10 px-4">
				<Slider {...settings} className="w-full max-w-[1440px]">
					{slides.map(
						(
							{
								title,
								content,
								price,
								priceForCouple,
								special,
								coupleSnag,
							},
							index
						) => {
							const isCoupleSnagVisible =
								currentTraining === 'group' && !!coupleSnag;
							return (
								<div key={index}>
									<div
										className={clsx(
											`h-[${sliderHeight}px] px-2 py-4 border-[2px] border-black rounded-sm hover:cursor-pointer`,
											deviceType === 'monitor'
												? index === getNextSlide()
													? `scale-100 h-[${sliderMainHeight}px]`
													: `mt-[15px] mx-2`
												: ''
										)}
									>
										{special && (
											<div
												className={clsx(
													'text-center pt-[2px] font-bold text-sm'
												)}
											>
												NAJCZĘSCIEJ WYBIERANY
											</div>
										)}

										<div
											className={clsx(
												'font-notoserif-custom-bold text-3xl text-center text-gray-500 pb-6 drop-shadow-lg',
												index === getNextSlide()
													? 'pt-2'
													: deviceType === 'monitor'
														? 'pt-6'
														: 'pt-2'
											)}
										>
											{title}
										</div>

										<div
											className={clsx(
												'font-notoserif-custom-bold text-5xl text-center text-rose-350 drop-shadow-lg',
												{
													'pb-6': !isCoupleSnagVisible,
												}
											)}
										>
											{currentTraining === 'personal'
												? price
												: priceForCouple}
										</div>
										{isCoupleSnagVisible && (
											<p className="text-center pb-4 text-sm text-gray-500">
												*{coupleSnag}
											</p>
										)}

										{content.map((value, index) => (
											<div
												key={index}
												className={clsx(
													'font-montserrat-custom-medium text-lg text-center',
													value === 'space'
														? 'pb-4'
														: '',
													getProperStyleToDisplayValue(
														value
													)
												)}
											>
												{getDisplayValue(value)}
											</div>
										))}
									</div>
								</div>
							);
						}
					)}
				</Slider>
			</div>
		</div>
	);
}
