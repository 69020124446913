import React from 'react';
import clsx from 'clsx';
import ImageGallery from 'react-image-gallery';
import { images } from '../../constants/about-me-gallery';
import { Button } from '../../components/button';

export default function AboutMePage() {
	return (
		<div className="mb-14">
			{/* SECOND SECTION */}

			<div className="mt-12" />

			<div
				className={clsx(
					'flex flex-col justify-center mx-4',
					'lg:flex-row lg:gap-10 lg:mx-0'
				)}
			>
				<img
					className={clsx(
						'hidden',
						'lg:block lg:mx-0 lg:mt-6 rounded-[36px] lg:min-w-[256px] lg:max-w-[256px] lg:shadow-none lg:transition-transform lg:hover:shadow-xl lg:hover:scale-105'
					)}
					alt="amelia"
					src="2.png"
				/>
				<div className="lg:min-w-[400px] lg:max-w-[720px]">
					<div
						className={clsx(
							'text-3xl font-playfair-custom-bold font-bold text-center',
							'lg:text-left lg:ml-6 lg:text-5xl'
						)}
					>
						Parę słów{' '}
						<span className="text-rose-350">o&nbsp;mnie</span>
					</div>
					<img
						className={clsx(
							'mt-4 mx-auto rounded-[36px] min-w-[128px] max-w-[256px] border shadow-lg',
							'lg:hidden'
						)}
						alt="amelia"
						src="2.png"
					/>
					<div className="mt-6 font-montserrat-custom-medium text-center lg:text-justify">
						Jako trenerka personalna specjalizuję się
						w&nbsp;modelowaniu sylwetki oraz poprawie komfortu
						codziennego życia.
					</div>
					<div
						className={clsx(
							'mt-4 font-montserrat-custom-medium text-center',
							'lg:mt-6 lg:text-justify'
						)}
					>
						Pracuję z&nbsp;osobami w&nbsp;różnym wieku
						i&nbsp;o&nbsp;różnych sylwetkach, jak również
						z&nbsp;tymi, którzy zmierzyć się muszą z&nbsp;różnymi
						problemami zdrowotnymi. Określam i&nbsp;pomagam
						realizować cele fitnessowe. Zwiększam świadomość moich
						klientów w&nbsp;obszarach zdrowego stylu życia
						i&nbsp;dbałości o&nbsp;zdrowie. Cieszę się zaufaniem
						wielu silnych i&nbsp;świadomych swojego zdrowia osób.
					</div>

					<div
						className={clsx(
							'mt-6 text-center font-bold font-montserrat-custom-medium text-lg',
							'lg:text-justify'
						)}
					>
						Przywracam ludziom wiarę w&nbsp;ich możliwości
						i&nbsp;dbam o&nbsp;to, aby byli w&nbsp;pełni sprawni
						ruchowo.
					</div>
				</div>
			</div>

			{/*	THIRD SECTION */}

			<div className="mt-24" />

			<div
				className={clsx(
					'flex flex-col justify-center mx-4',
					'lg:flex-row-reverse lg:gap-10 lg:mx-0'
				)}
			>
				<img
					className={clsx(
						'rounded-full min-w-[256px] max-w-[320px] mx-auto hidden',
						'lg:block lg:mt-16 lg:mx-0 lg:mb-10 lg:min-w-[384px] lg:max-w-[384px] lg:border shadow-lg lg:shadow-none lg:transition-transform lg:hover:shadow-xl lg:hover:scale-105'
					)}
					alt="amelia"
					src="3.png"
				/>
				<div
					className={clsx(
						'mt-6 text-center',
						'lg:min-w-[400px] lg:max-w-[562px] lg:text-left mx-2 lg:mx-0'
					)}
				>
					<div
						className={clsx(
							'text-3xl font-bold font-playfair-custom-bold',
							'lg:text-5xl lg:ml-6'
						)}
					>
						Kim jestem
					</div>
					<span
						className={clsx(
							'font-bold text-rose-350 mt-[-2px] text-2xl font-playfair-custom-bold',
							'lg:text-4xl lg:mt-1 lg:ml-6'
						)}
					>
						<span className="text-black">i&nbsp;co</span> mnie
						wyróżnia?
					</span>
					<img
						className={clsx(
							'mt-4 mx-auto rounded-[36px] min-w-[256px] max-w-[100%] border shadow-lg',
							'lg:hidden'
						)}
						alt="amelia"
						src="3.png"
					/>
					<div className="mt-10 font-montserrat-custom-medium text-center lg:text-justify">
						Nazywam się Amelia Pałczyńska. Jestem studentką
						Wychowania Fizycznego na Uniwersytecie Rzeszowskim. Od
						najmłodszych lat sport był obecny w moim życiu -
						począwszy od paru lat przetańczonych w grupie tanecznej,
						amartoskiej grze w tenisa ziemnego, skończywszy na
						zawodach sprinterskich. Zawsze lubiłam pomagać innym i
						wiedziałam, że z tym chcę wiązać swoją przyszłość.
					</div>
					<div className="mt-6 font-montserrat-custom-medium text-center lg:text-justify">
						Od 2022 roku jestem trenerką personalną. Natomiast stale
						rozwijam się pod kątem treningów medycznych. Moim celem
						jest pomoc w przekraczaniu własnych barier, przekazanie
						wiedzy, która sprawi, że Twoje treningi będą skuteczne i
						bezpieczne, a komfort codziennego życia znacznie się
						poprawi.
					</div>
				</div>
			</div>

			{/*	FOURTH SECTION */}

			<div className={clsx('mt-10', 'lg:mt-24')} />

			<div
				className={clsx(
					'flex flex-col items-center gap-4 lg:gap-10 mx-2 lg:mx-0 mt-20 lg:mt-0'
				)}
			>
				<div
					className={clsx(
						'text-3xl font-playfair-custom-bold font-bold text-center',
						'lg:text-5xl'
					)}
				>
					Moje <span className="text-rose-350">certyfikaty</span>
				</div>

				<div className="flex items-center lg:min-w-[600px] lg:max-w-[800px] h-[620px] md:h-[1100px] xl:h-[900px]">
					<ImageGallery
						items={images}
						showFullscreenButton={false}
						showPlayButton={false}
					/>
				</div>
			</div>

			{/*	FIFTH SECTION */}

			<div className={clsx('mt-10', 'lg:mt-24')} />

			<div
				className={clsx(
					'flex flex-col-reverse items-center',
					'lg:flex-row lg:gap-10 lg:justify-center'
				)}
			>
				<div
					className={clsx(
						'mt-6 text-center mb-20',
						'lg:min-w-[400px] lg:max-w-[550px] lg:text-center lg:mb-0'
					)}
				>
					<div
						className={clsx(
							'text-4xl font-bold font-montserrat-custom-light',
							'lg:text-5xl'
						)}
					>
						Jeśli interesuje Cię
					</div>
					<div
						className={clsx(
							'text-4xl font-bold font-montserrat-custom-light text-center',
							'lg:text-5xl'
						)}
					>
						współpraca ze&nbsp;mną,
					</div>
					<div
						className={clsx(
							'text-2xl font-bold text-black font-montserrat-custom-extralight text-center mt-3 flex justify-center',
							'lg:mt-12'
						)}
					>
						<Button variant="secondary" isLink href="/contact">
							ZAPISZ SIĘ
						</Button>
					</div>
				</div>
				<img
					className={clsx(
						'rounded-full min-w-[256px] max-w-[300px] mx-auto',
						'lg:mt-6 lg:mx-0 lg:mb-20 lg:min-w-[384px] lg:max-w-[384px] lg:border shadow-lg lg:shadow-none lg:transition-transform lg:hover:shadow-xl lg:hover:scale-105'
					)}
					alt="amelia"
					src="contact_amelia-removebg-preview.png"
				/>
			</div>
		</div>
	);
}
