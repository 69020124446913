import * as React from 'react';
import { Link } from 'react-router-dom';

export const GlobalError = () => {
	return (
		<div className="w-full h-[100vh]">
			<div className="flex justify-center h-full mt-10">
				<div className="flex flex-col items-center h-full">
					<h1 className="font-bold text-3xl">Upss!</h1>
					<p className="text-xl">
						Coś poszło nie tak... Zweryfikuj adres strony.
					</p>

					<Link to={'/'} className={'mt-10 font-bold text-3xl'}>
						Powróć do strony głównej
					</Link>
				</div>
			</div>
		</div>
	);
};
