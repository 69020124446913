export const images = [
	{
		original: 'https://i.imgur.com/M9onMIh.jpeg',
	},
	{
		original: 'https://i.imgur.com/GjjDcd9.jpeg',
	},
	{
		original: 'https://i.imgur.com/Cd48J4q.jpeg',
	},
	{
		original: 'https://i.imgur.com/jNisbJm.jpeg',
	},
	{
		original: 'https://i.imgur.com/sE6plAM.jpeg',
	},
	{
		original: 'certificates/1.jpg',
	},
	{
		original: 'certificates/7.jpg',
	},
	{
		original: 'certificates/8.jpg',
	},
	// {
	// 	original: "https://i.imgur.co/doQ5pOQ.jpeg",
	// },
];
