import React, { useState } from 'react';
import Slider from 'react-slick';
import { useDeviceType } from '../../hooks/use-device-type';
import clsx from 'clsx';
import Avatar from '@mui/material/Avatar';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slides = [
	{
		avatar: 'K',
		avatar_color: 'pink',
		person: 'Krystian Janas',
		rate: 5,
		opinions: 6,
		date: 'Marzec, 2024',
		description: [
			'Pracuję z Amelią jako moim trenerem personalnym od pewnego czasu i muszę przyznać, że jestem niezwykle zadowolony z efektów naszej współpracy. Amelia dostarcza ogromną dawkę motywacji, która pomaga mi przekraczać moje granice i stale dążyć do poprawy. Jej wskazówki i podpowiedzi są zawsze trafne i pomocne, co jest nieocenione, szczególnie przy wykonywaniu nowych lub trudnych ćwiczeń. Jestem pod dużym wrażeniem, jak skrupulatnie Amelia koryguje moją technikę, co nie tylko zwiększa efektywność treningu, ale także minimalizuje ryzyko kontuzji.\n' +
				'\n' +
				'Punktualność Amelii jest bez zarzutu; zawsze jest gotowa na czas, co pozwala mi w pełni wykorzystać każdą minutę naszych treningów. Co więcej, wprowadza nowe ćwiczenia i czynności treningowe do mojego planu, co sprawia, że każdy trening jest interesujący i wyzwalający. Dzięki temu, nigdy nie czuję monotonii, a moja motywacja pozostaje na wysokim poziomie.\n' +
				'\n' +
				'Z całą pewnością mogę polecić Amelię każdemu, kto szuka trenera personalnego, aby nauczyć się poprawnie wykonywać ćwiczenia i zauważać efekty już od pierwszych spotkań! :)',
		],
	},
	{
		avatar: 'P',
		avatar_color: 'purple',
		person: 'Patryk Arendt',
		rate: 5,
		opinions: 3,
		date: 'Marzec, 2024',
		description: [
			'Jestem niezmiernie zadowolony z współpracy z Panią Amelią, jej zaangażowanie, profesjonalizm i empatia przekroczyły moje oczekiwania, przynosząc wyjątkowo pozytywne rezultaty.\n' +
				'Posiada ona nie tylko dogłębną wiedzę na temat ćwiczeń i planowania treningów, ale także potrafiła dostosować je od moich indywidualnych potrzeb i celów. Zawsze była gotowa odpowiedzieć na moje pytania, czy rozwiać wątpliwości.\n' +
				'\n' +
				'Ponadto, doceniam również elastyczność Pani trener w dostosowywaniu planu treningowego do zmieniających się warunków oraz jej umiejętność motywowania mnie do stałego podnoszenia poprzeczki i dążenia do kolejnych celów.\n' +
				'\n' +
				'Całkowicie polecam Panią Amelię jako profesjonalistkę, która nie tylko pomoże osiągnąć zamierzone cele, ale także zainspiruje do dalszego rozwoju i utrzymania zdrowego, aktywnego stylu życia.',
		],
	},
	{
		avatar: 'A',
		avatar_color: 'gray',
		person: 'Aleksandra Bębnik',
		rate: 5,
		opinions: 3,
		date: 'Grudzień, 2023',
		description: [
			'Właściwy człowiek, na właściwym miejscu, współpraca z Amelką to czysta przyjemność 😊 Amelka jest bardzo ambitną osobą, jak i znającą się na tym co robi. Bardzo szczegółowa wiedza w prowadzeniu treningu, przykłada dużą uwagę do poprawności wykonywanych ćwiczeń. Cieszę się, że trafiłam na nią, daje dużego kopa i pomaga w osiągnięciu tego czego się chce 😊 Polecam z całego serca! ❤️',
		],
	},
	{
		avatar: 'K',
		avatar_color: 'green',
		person: 'Katarzyna',
		rate: 5,
		opinions: 1,
		date: 'Luty, 2024',
		description: [
			'Polecam współpracę z Amelią z całego serduszka 😊 widać że to co robi to jej pasja. Do tego jest bardzo sympatyczną osobą. Nasza współpraca spełnia moje oczekiwania co do zmian w moim zdrowiu, samopoczuciu i sylwetce ❤️',
		],
	},
];

export default function OpinionPage() {
	const sliderHeight = 600;
	const sliderMainHeight = sliderHeight + 50;
	const [currentSlide, setCurrentSlide] = useState(slides.length - 1);
	const deviceType = useDeviceType();

	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: deviceType === 'monitor' ? 3 : 1,
		slidesToScroll: 1,
		initialSlide: deviceType === 'monitor' ? slides.length - 1 : 1,
		afterChange: (currentSlide: number) => setCurrentSlide(currentSlide),
	};

	const getNextSlide = () => {
		const nextSlide = currentSlide + 1;
		if (nextSlide > slides.length - 1) {
			return 0;
		}
		return nextSlide;
	};

	return (
		<>
			<div className="text-center pt-8 font-notoserif-custom-bold text-3xl px-2">
				Chcesz zobaczyć, co mówią o mnie{' '}
				<span className="text-rose-350">moi podopieczni?</span>
			</div>
			<div className="flex justify-center mt-10 px-4 min-h-[690px]">
				<Slider {...settings} className={`w-full max-w-[1440px] `}>
					{slides.map(
						(
							{
								avatar,
								avatar_color,
								person,
								rate,
								date,
								description,
								opinions,
							},
							slideIndex
						) => (
							<div key={slideIndex} className={``}>
								<div
									className={clsx(
										`bg-gray-200 rounded-lg`,
										deviceType === 'monitor'
											? slideIndex === getNextSlide()
												? `h-[${sliderMainHeight}px]`
												: `mt-[15px] mx-2 blur-[1px]`
											: ''
									)}
								>
									<div className="pt-4 px-4">
										<div className="flex flex-row gap-2">
											<Avatar
												sx={{ bgcolor: avatar_color }}
											>
												{avatar}
											</Avatar>
											<div className="flex flex-col">
												<div className="font-bold drop-shadow-lg">
													{person}
												</div>
												<div className="drop-shadow-lg text-gray-500 mt-[-3px] text-[14px]">
													{opinions} opini
													{opinions === 1
														? 'a'
														: opinions === 3
															? 'e'
															: ''}
												</div>
											</div>
										</div>
										<div
											className={clsx(
												'pt-4 text-justify',
												deviceType === 'monitor' &&
													slideIndex ===
														getNextSlide()
													? ''
													: deviceType === 'monitor'
														? 'text-[14px]'
														: ''
											)}
										>
											{description.map((value, index) => (
												<div
													key={index}
													className={clsx(
														deviceType ===
															'monitor' &&
															slideIndex ===
																getNextSlide()
															? 'line-clamp-25'
															: deviceType ===
																  'monitor'
																? 'line-clamp-25'
																: 'line-clamp-25'
													)}
												>
													{value}
												</div>
											))}
										</div>
										<div className="pb-4"></div>
									</div>
								</div>
							</div>
						)
					)}
				</Slider>
			</div>
		</>
	);
}
