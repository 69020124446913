import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useDeviceType } from '../../hooks/use-device-type';
import InstagramIcon from '@mui/icons-material/Instagram';
import { Button } from 'components/button';

export default function ContactPage() {
	const deviceType = useDeviceType();

	return (
		<div>
			<div
				className={clsx(
					'flex gap-10 py-8',
					'xl:flex-row lg:justify-center',
					'flex-col items-center'
				)}
			>
				<div className="lg:w-[500px]">
					<div
						className={clsx(
							'mt-6 text-center mb-20',
							'lg:min-w-[400px] lg:max-w-[550px] lg:text-center lg:mb-0'
						)}
					>
						<div
							className={clsx(
								'text-4xl font-bold font-montserrat-custom-light',
								'lg:text-5xl'
							)}
						>
							Jeśli interesuje Cię
						</div>
						<div
							className={clsx(
								'text-4xl font-bold font-montserrat-custom-light text-center',
								'lg:text-5xl'
							)}
						>
							współpraca ze&nbsp;mną,
						</div>
						<div
							className={clsx(
								'text-2xl font-bold text-black font-montserrat-custom-extralight text-center mt-3 flex justify-center',
								'lg:mt-12'
							)}
						>
							<Button
								variant="tertiary"
								isLink
								href="https://tiny.pl/dmdqw"
								openInNewTab
							>
								Zapisz się wypełniając formularz
							</Button>
						</div>
					</div>
					<div className="flex flex-col items-center">
						<div className={'w-1/2 w-max-[300px] py-4'}>
							<img
								src="/contact_amelia.png"
								alt="amelia"
								className="mt-2 lg:mt-6 rounded-full w-full border shadow-lg lg:shadow-none lg:transition-transform lg:hover:shadow-xl lg:hover:scale-105"
							/>
						</div>
						<div className="text-3xl font-bold font-notoserif-custom-bold ">
							Amelia Pałczyńska
						</div>
						<div className="text-md font-bold font-notoserif-custom-bold mb-10">
							TRENER PERSONALNY
						</div>
						<div className="mb-2 font-notoserif-custom-bold text-xl lg:text-[1.1rem]">
							<a
								href="https://www.instagram.com/amelia_palczynska?igsh=NWczdGc3aTczZmJ2"
								className="flex gap-1 items-center"
							>
								<InstagramIcon />
								amelia_palczynska
							</a>
						</div>
						<div className="mb-2 font-notoserif-custom-bold">
							<a
								href="mailto:ameliapalczynska6@gmail.com"
								className="flex gap-2 items-center text-xl lg:text-[1.1rem]"
							>
								<FontAwesomeIcon icon={'envelope'} />
								ameliapalczynska6@gmail.com
							</a>
						</div>
						<div className="font-notoserif-custom-bold text-xl lg:text-[1.1rem]">
							<a href="tel:508871292">
								📞&nbsp;&nbsp;508 871 292
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="flex flex-row mt-4 w-full justify-center">
				<div>
					<div className="font-montserrat-custom-medium font-bold text-xl text-center mb-2">
						Gdzie możesz mnie spotkać?
					</div>

					<iframe
						title="localization"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2563.568417026629!2d22.015320076531903!3d50.019443971513034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473cfa5c97007f1b%3A0x9b684ad686b5301f!2sCityFit!5e0!3m2!1spl!2spl!4v1702242749802!5m2!1spl!2spl"
						width={deviceType === 'monitor' ? '1000' : '400px'}
						height={deviceType === 'monitor' ? '500' : '300'}
						loading="lazy"
						referrerPolicy="no-referrer-when-downgrade"
						className="rounded-2xl mb-10 border"
					/>
				</div>
			</div>
		</div>
	);
}
