import clsx from 'clsx';
import { Button } from 'components/button';
import { Hero } from 'components/hero';
import { TransformationsSlider } from 'components/transformations-slider';
import FAQPage from 'pages/faq/faq';
import OfferPage from 'pages/offer/offer';
import OpinionPage from 'pages/opinions/opinions';
import React from 'react';

export const MainPage = () => {
	return (
		<div className="mb-14 flex flex-col gap-y-10 lg:gap-y-24 font-montserrat-custom-medium">
			<Hero />
			<div
				className={clsx(
					'flex flex-col justify-center mx-4',
					'lg:flex-row-reverse lg:gap-10 lg:mx-0',
					'bg-white'
				)}
			>
				<img
					className={clsx(
						'rounded-full min-w-[256px] max-w-[320px] mx-auto hidden',
						'lg:block lg:mt-16 lg:mx-0 lg:mb-10 lg:min-w-[384px] lg:max-w-[384px] lg:border shadow-lg lg:shadow-none lg:transition-transform lg:hover:shadow-xl lg:hover:scale-105'
					)}
					alt="amelia"
					src="3.png"
				/>
				<div
					className={clsx(
						'mt-6 text-center',
						'lg:min-w-[400px] lg:max-w-[562px] lg:text-left mx-2 lg:mx-0'
					)}
				>
					<div
						className={clsx(
							'text-3xl font-bold font-playfair-custom-bold',
							'lg:text-5xl lg:ml-6'
						)}
					>
						Kim jestem
					</div>
					<span
						className={clsx(
							'font-bold text-rose-350 mt-[-2px] text-2xl font-playfair-custom-bold',
							'lg:text-4xl lg:mt-1 lg:ml-6'
						)}
					>
						<span className="text-black">i&nbsp;co</span> mnie
						wyróżnia?
					</span>
					<img
						className={clsx(
							'mt-4 mx-auto rounded-[36px] min-w-[256px] max-w-[100%] border shadow-lg',
							'lg:hidden'
						)}
						alt="amelia"
						src="3.png"
					/>
					<div className="mt-10 font-montserrat-custom-medium text-center lg:text-justify">
						Nazywam się Amelia Pałczyńska. Jestem studentką
						Wychowania Fizycznego na Uniwersytecie Rzeszowskim. Od
						najmłodszych lat sport był obecny w moim życiu -
						począwszy od paru lat przetańczonych w grupie tanecznej,
						amartoskiej grze w tenisa ziemnego, skończywszy na
						zawodach sprinterskich. Zawsze lubiłam pomagać innym i
						wiedziałam, że z tym chcę wiązać swoją przyszłość.
					</div>
					<div className="mt-6 font-montserrat-custom-medium text-center lg:text-justify">
						Od 2022 roku jestem trenerką personalną. Natomiast stale
						rozwijam się pod kątem treningów medycznych. Moim celem
						jest pomoc w przekraczaniu własnych barier, przekazanie
						wiedzy, która sprawi, że Twoje treningi będą skuteczne i
						bezpieczne, a komfort codziennego życia znacznie się
						poprawi.
					</div>
				</div>
			</div>
			<div
				className={clsx(
					'border-b-gray-200 border-[1px] rounded-[20px] py-8 shadow-xl w-[92%] my-6 mx-auto',
					'lg:w-[75%] lg-min-w-[500px] lg:px-10 lg:mx-auto'
				)}
			>
				<div
					className={clsx(
						'font-playfair-custom-bold text-4xl mb-4 text-center',
						'lg:text-5xl lg:text-center'
					)}
				>
					Jak mogę Ci <span className="text-rose-350">pomóc?</span>
				</div>

				<div
					className={clsx(
						'flex flex-col gap-2',
						'lg:flex lg:flex-row lg:gap-10'
					)}
				>
					<div className="flex gap-4 lg:gap-8 flex-col py-5 lg:py-3 px-4 lg:px-0 items-center">
						<img
							src="/contact/2.png"
							alt="1"
							className={clsx(
								'w-[64px] min-w-[96px] shadow-lg',
								'rounded-full lg:w-[128px] lg:min-w-[128px] border lg:transition-transform lg:shadow-none lg:hover:shadow-lg lg:hover:scale-105'
							)}
						/>
						<div className="flex flex-col">
							<div className="font-montserrat-custom-medium text-lg font-bold text-center mb-2 lg:mb-4">
								Poprawa kondycji i&nbsp;sylwetki
							</div>
							<div className="font-montserrat-custom-medium text-md text-center">
								Moje metody treningowe są bardzo skuteczne
								zarówno dla osób, które chcą zbudować siłę, jak
								i&nbsp;tych, którzy pragną wrócić do formy po
								długiej przerwie w&nbsp;aktywności fizycznej.
							</div>
						</div>
					</div>

					<div className="h-[1px] bg-gray-300 w-[95%] mx-auto rounded lg:hidden"></div>

					<div className="flex gap-4 lg:gap-8 flex-col py-5 lg:py-3 px-4 lg:px-0 items-center">
						<img
							src="/contact/1.png"
							alt="2"
							className={clsx(
								'w-[64px] min-w-[96px] shadow-lg',
								'rounded-full lg:w-[128px] lg:min-w-[128px] border lg:transition-transform lg:shadow-none lg:hover:shadow-lg lg:hover:scale-105'
							)}
						/>
						<div className="flex flex-col">
							<div className="font-montserrat-custom-medium text-lg font-bold text-center mb-2 lg:mb-4">
								Rozwiązanie problemów bólowych i&nbsp;wzrost
								sprawności
							</div>
							<div className="font-montserrat-custom-medium text-md text-center">
								Zapewniam kompleksowe wsparcie przy pokonywaniu
								bólu pleców, poprawie ogólnej sprawności oraz
								radzeniu sobie z&nbsp;ograniczeniami ruchowymi.
								Moim celem jest przywrócenie Ci pełni zdrowia
								i&nbsp;sprawności.
							</div>
						</div>
					</div>

					<div className="h-[1px] bg-gray-300 w-[95%] mx-auto rounded lg:hidden"></div>

					<div className="flex gap-4 lg:gap-8 flex-col py-5 lg:py-3 px-4 lg:px-0 items-center">
						<img
							src="/contact/3.png"
							alt="3"
							className={clsx(
								'w-[64px] min-w-[96px] shadow-lg',
								'rounded-full lg:w-[128px] lg:min-w-[128px] border lg:transition-transform lg:shadow-none lg:hover:shadow-lg lg:hover:scale-105'
							)}
						/>
						<div className="flex flex-col">
							<div className="font-montserrat-custom-medium text-lg font-bold text-center mb-2 lg:mb-4">
								Treningi pełne energii i&nbsp;motywacji
							</div>
							<div className="font-montserrat-custom-medium text-md text-center">
								Oferuję ćwiczenia, które są nie tylko efektywne,
								ale i&nbsp;przyjemne. Zadbam o&nbsp;to, aby
								każdy trening był interesujący
								i&nbsp;motywujący, dopasowany do Twoich
								indywidualnych preferencji.
							</div>
						</div>
					</div>

					<div className="h-[1px] bg-gray-300 w-[95%] mx-auto rounded lg:hidden"></div>

					<div className="flex gap-4 lg:gap-8 flex-col py-5 lg:py-3 px-4 lg:px-0 items-center">
						<img
							src="/contact/4.png"
							alt="4"
							className={clsx(
								'w-[64px] min-w-[96px] shadow-lg',
								'rounded-full lg:w-[128px] lg:min-w-[128px] border lg:transition-transform lg:shadow-none lg:hover:shadow-lg lg:hover:scale-105'
							)}
						/>
						<div className="flex flex-col">
							<div className="font-montserrat-custom-medium text-lg font-bold text-center mb-2 lg:mb-4">
								Pozbywanie się kompleksów i&nbsp;budowanie
								pewności siebie
							</div>
							<div className="font-montserrat-custom-medium text-md text-center">
								Moje programy treningowe są opracowane tak, abyś
								szybko zauważył zmiany w&nbsp;swojej sylwetce
								i&nbsp;samopoczuciu. Popracujemy razem nad Twoim
								pozytywnym postrzeganiem ciała
								i&nbsp;umocnieniem pewności siebie.
							</div>
						</div>
					</div>
				</div>
			</div>
			<OfferPage />
			<TransformationsSlider />
			<OpinionPage />
			<FAQPage />
			<div
				className={clsx(
					'flex flex-col-reverse items-center',
					'lg:flex-row lg:gap-10 lg:justify-center'
				)}
			>
				<div
					className={clsx(
						'mt-6 text-center mb-20',
						'lg:min-w-[400px] lg:max-w-[550px] lg:text-center lg:mb-0'
					)}
				>
					<div
						className={clsx(
							'text-4xl font-bold font-montserrat-custom-light',
							'lg:text-5xl'
						)}
					>
						Jeśli interesuje Cię
					</div>
					<div
						className={clsx(
							'text-4xl font-bold font-montserrat-custom-light text-center',
							'lg:text-5xl'
						)}
					>
						współpraca ze&nbsp;mną,
					</div>
					<div
						className={clsx(
							'text-2xl font-bold text-black font-montserrat-custom-extralight text-center mt-3 flex justify-center',
							'lg:mt-12'
						)}
					>
						<Button variant="secondary" isLink href="/contact">
							ZAPISZ SIĘ
						</Button>
					</div>
				</div>
				<img
					className={clsx(
						'rounded-full min-w-[256px] max-w-[300px] mx-auto',
						'lg:mt-6 lg:mx-0 lg:mb-20 lg:min-w-[384px] lg:max-w-[384px] lg:border shadow-lg lg:shadow-none lg:transition-transform lg:hover:shadow-xl lg:hover:scale-105'
					)}
					alt="amelia"
					src="contact_amelia-removebg-preview.png"
				/>
			</div>
		</div>
	);
};
