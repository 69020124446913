import * as React from 'react';
import { toast } from 'react-toastify';
import { useState } from 'react';
import emailjs from 'emailjs-com';
import { ColorRing } from 'react-loader-spinner';

export default function EBookPage() {
	const [email, setEmail] = useState('');
	const [sendingEmail, setSendingEmail] = useState(false);

	const handleEmailChange = event => {
		setEmail(event.target.value);
	};

	const handleSubmit = async event => {
		event.preventDefault();

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

		if (!emailRegex.test(email)) {
			toast.error('Podaj poprawny adres e-mail.');
			return;
		}

		if (sendingEmail) {
			return;
		}
		setSendingEmail(true);

		try {
			const templateParams = {
				user_email: email,
			};

			const result = await emailjs.send(
				'service_edwzoz8',
				'template_amj4uoc',
				templateParams,
				'cEKCWkjLSQrCEzKfx' // public key from emailJS
			);

			if (result.status === 200) {
				setEmail('');
				toast.success('E-book został wysłany!');
			} else {
				toast.error('Wystąpił błąd przy wysyłaniu e-booka.');
			}
		} catch (error) {
			toast.error('Wystąpił błąd przy wysyłaniu e-booka.');
		} finally {
			setSendingEmail(false);
		}
	};

	return (
		<div
			className="relative w-full h-screen bg-cover flex justify-center items-center"
			style={{ backgroundImage: 'url("gym.jpg")' }}
		>
			<div className="absolute top-0 left-0 right-0 bottom-0 bg-black bg-opacity-50 backdrop-blur-lg"></div>
			<div className="relative bg-white bg-opacity-80 p-10 rounded-lg shadow-lg text-center z-10">
				<h2>Hej, z tej strony Amelia Pałczyńska!</h2>
				<h2>Miło mi, że chcesz otrzymać mojego e-booka.</h2>
				<h1 className="font-bold text-lg">
					Aby go odebrać, wpisz swój adres e-mail poniżej.
				</h1>
				<br />
				<form onSubmit={handleSubmit}>
					<input
						type="email"
						placeholder="Wpisz swój e-mail"
						value={email}
						onChange={handleEmailChange}
						className="p-2 w-full mb-2 rounded border-gray-300 focus:border-rose-350 border-2 border-solid outline-none"
						required
					/>
					<button
						disabled={sendingEmail}
						type="submit"
						className="p-2.5 text-white hover:text-rose-350 bg-rose-350 hover:bg-white border-none rounded cursor-pointer text-lg mt-2 transition-colors"
					>
						<div className="flex flex-row items-center">
							Wyślij e-booka
							{sendingEmail ? (
								<ColorRing
									colors={[
										'#FFFFFF',
										'#FFFFFF',
										'#FFFFFF',
										'#FFFFFF',
										'#FFFFFF',
									]}
									height="24"
									width="24"
									visible
								/>
							) : null}
						</div>
					</button>
				</form>
			</div>
		</div>
	);
}
