import clsx from 'clsx';
import { useDeviceType } from 'hooks/use-device-type';
import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const slides = [
	{
		photoSrc: 'transformations/transformation-1.jpg',
		photoAlt: 'transformations/transformation-1',
	},
	{
		photoSrc: 'transformations/transformation-2.jpg',
		photoAlt: 'transformations/transformation-2',
	},
	{
		photoSrc: 'transformations/transformation-3.jpg',
		photoAlt: 'transformations/transformation-3',
	},
	{
		photoSrc: 'transformations/transformation-4.jpg',
		photoAlt: 'transformations/transformation-4',
	},
	{
		photoSrc: 'transformations/transformation-5.jpg',
		photoAlt: 'transformations/transformation-5',
	},
	{
		photoSrc: 'transformations/transformation-6.jpg',
		photoAlt: 'transformations/transformation-6',
	},
	{
		photoSrc: 'transformations/transformation-7.jpg',
		photoAlt: 'transformations/transformation-7',
	},
];

export const TransformationsSlider = () => {
	const sliderHeight = 600;
	const sliderMainHeight = sliderHeight + 50;
	const [currentSlide, setCurrentSlide] = useState(slides.length - 1);
	const deviceType = useDeviceType();

	const settings = {
		dots: true,
		infinite: true,
		speed: 300,
		slidesToShow: deviceType === 'monitor' ? 3 : 1,
		slidesToScroll: 1,
		initialSlide: deviceType === 'monitor' ? slides.length - 1 : 1,
		afterChange: (currentSlide: number) => setCurrentSlide(currentSlide),
	};

	const getNextSlide = () => {
		const nextSlide = currentSlide + 1;
		if (nextSlide > slides.length - 1) {
			return 0;
		}
		return nextSlide;
	};

	return (
		<>
			<h2 className="text-center pt-8 font-notoserif-custom-bold text-3xl px-2">
				Przemiany
			</h2>
			<div className="flex justify-center mt-2 px-4">
				<Slider {...settings} className={`w-full max-w-[1440px] `}>
					{slides.map(({ photoSrc, photoAlt }, slideIndex) => (
						<div key={slideIndex} className={``}>
							<div
								className={clsx(
									`bg-gray-200 rounded-lg `,
									deviceType === 'monitor'
										? slideIndex === getNextSlide()
											? `h-[${sliderMainHeight}px]`
											: `mt-[15px] mx-2 blur-[1px]`
										: ''
								)}
							>
								<img src={photoSrc} alt={photoAlt} />
							</div>
						</div>
					))}
				</Slider>
			</div>
		</>
	);
};
