import React, { useRef, useState } from 'react';
import clsx from 'clsx';
import { useDeviceType } from '../../hooks/use-device-type';
import EastIcon from '@mui/icons-material/East';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { BENEFITS, FAQ } from '../../constants/faq';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import CheckIcon from '@mui/icons-material/Check';

export default function FAQPage() {
	const deviceType = useDeviceType();

	const FAQContent = ({
		question,
		answer,
	}: {
		question: string;
		answer: string[];
	}) => {
		const [isQuestionOpen, setIsQuestionOpen] = useState(false);
		const contentRef = useRef(null);

		const maxHeight =
			isQuestionOpen && contentRef.current
				? `${contentRef.current.scrollHeight}px`
				: '0';

		return (
			<div>
				<button
					className={clsx(
						'w-full text-left p-4 border rounded-2xl flex gap-2 hover:border-rose-350 hover:bg-pink-100 transition-all',
						isQuestionOpen ? 'border-rose-350 bg-pink-50' : ''
					)}
					onClick={() => setIsQuestionOpen(prevState => !prevState)}
				>
					{isQuestionOpen ? (
						<KeyboardDoubleArrowDownIcon className="ml-0" />
					) : (
						<KeyboardDoubleArrowRightIcon />
					)}
					<span
						className={clsx(
							'font-montserrat-custom-medium',
							isQuestionOpen ? 'text-gray-600' : ''
						)}
					>
						{question}
					</span>
				</button>
				<div
					ref={contentRef}
					style={{
						maxHeight,
						overflow: 'hidden',
						transition: 'max-height 0.5s ease-in-out',
					}}
					aria-expanded={isQuestionOpen}
				>
					<div className="p-4">
						{answer.map((value, index) => (
							<div
								key={index}
								className="flex gap-2 items-center my-1"
							>
								<div className="flex self-start">
									<ChevronRightIcon fontSize="medium" />
								</div>
								<span className="font-montserrat-custom-medium">
									{value}
								</span>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="w-full h-full mb-12">
			<div
				className={clsx(
					'',
					deviceType === 'smartphone' ? 'mx-2' : '',
					deviceType === 'monitor'
						? 'flex flex-col mx-auto min-w-[400px] max-w-[860px]'
						: ''
				)}
			>
				<div className="my-6">
					<div
						className={clsx(
							'font-bold font-playfair-custom-bold ',
							deviceType === 'smartphone'
								? 'text-[26px]'
								: 'text-3xl'
						)}
					>
						PODEJMUJĄC WSPÓŁPRACĘ
					</div>
					<div
						className={clsx(
							'font-bold font-playfair-custom-bold ',
							deviceType === 'smartphone'
								? 'text-[26px] mt-[-6px]'
								: 'text-3xl'
						)}
					>
						ZE MNĄ{' '}
						<span
							className={clsx(
								'text-rose-350 mt-[-2px] text-2xl',
								'lg:text-3xl lg:mt-1'
							)}
						>
							OTRZYMUJESZ:
						</span>
					</div>
				</div>

				<div className="mb-8">
					{BENEFITS.map(({ benefitText }, index) => (
						<div
							key={index}
							className={clsx(
								'flex gap-5 items-center my-1',
								deviceType === 'smartphone' ? 'gap-3 my-4' : ''
							)}
						>
							{deviceType === 'smartphone' ? (
								<CheckIcon fontSize="medium" />
							) : (
								<EastIcon fontSize="large" />
							)}
							<span className="font-montserrat-custom-medium">
								{benefitText}
							</span>
						</div>
					))}
				</div>

				<div className="w-full flex flex-col gap-4 mb-10">
					<div
						className={clsx(
							'font-playfair-custom-bold text-rose-350 mt-[-2px] text-2xl',
							'lg:text-3xl lg:mt-1'
						)}
					>
						FAQ:
					</div>

					{FAQ.map(({ question, answers }, index) => (
						<FAQContent
							key={index}
							question={question}
							answer={answers}
						/>
					))}
				</div>
			</div>
		</div>
	);
}
