import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

export const ButtonVariants = ['primary', 'secondary', 'tertiary'] as const;
type ButtonVariant = (typeof ButtonVariants)[number];

type ButtonProps = {
	children: React.ReactNode;
	onClick?: () => void;

	variant?: ButtonVariant;

	isLink?: boolean;
	href?: string;
	additionalClassName?: string;
	openInNewTab?: boolean;
};

export const Button = ({
	children,
	onClick,
	isLink,
	href,
	openInNewTab = false,
	variant = 'primary',
	additionalClassName,
}: ButtonProps) => {
	const getButtonVariant = () => {
		if (variant === 'secondary') {
			return 'px-14 py-4 border-[2px] border-black hover:border-rose-350 hover:text-rose-350 hover:cursor-pointer hover:shadow-lg rounded-sm';
		} else if (variant === 'tertiary') {
			return 'px-10 py-4 border-[2px] border-black hover:border-rose-350 hover:text-rose-350 hover:cursor-pointer hover:shadow-lg rounded-sm text-lg';
		}
		return 'py-4 px-8 text-white font-bold w-full rounded-full text-center bg-pink-600 hover:bg-rose-350 hover:cursor-pointer hover:shadow';
	};

	return isLink && href ? (
		<Link
			to={href}
			className={clsx(
				getButtonVariant(),
				additionalClassName ? additionalClassName : ''
			)}
			target={openInNewTab ? '_blank' : '_self'}
		>
			{children}
		</Link>
	) : (
		<div
			onClick={onClick}
			className={clsx(
				getButtonVariant(),
				additionalClassName ? additionalClassName : ''
			)}
		>
			{children}
		</div>
	);
};
