import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDeviceType } from '../../hooks/use-device-type';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

const options = [
	{
		label: 'O mnie',
		href: '/about-me',
	},
	{
		label: 'Oferta',
		href: '/offer',
	},
	{
		label: 'Trening',
		href: '/training',
	},
	{
		label: 'Opinie',
		href: '/opinions',
	},
	// {
	// 	label: 'Kalendarz',
	// 	href: '/calendar',
	// },
	{
		label: 'Kontakt',
		href: '/contact',
	},
	{
		label: 'FAQ',
		href: '/faq',
	},
];

export const Navigation = () => {
	const { pathname } = useLocation();
	const deviceType = useDeviceType();

	const [menuOpen, setMenuOpen] = useState(false);

	const handleMenuClick = () => {
		setMenuOpen(prevState => !prevState);
	};

	return deviceType === 'monitor' ? (
		<div className="w-full md:fixed md:bg-white md:z-10 h-[75px] md:h-[100px] border-b-2 border-b-yellow-900 border-opacity-10">
			<div className="flex flex-row lg:mx-[20%] md:mx-[4%] justify-between h-full items-center">
				<Link to="/" className="justify-self-start">
					<img
						className="max-w-[64px]"
						src="amelia-palczynska-logo.png"
						alt="amelia-palczynska-logo"
					/>
				</Link>
				{options.map(({ label, href }) => (
					<Link
						onClick={() => setMenuOpen(false)}
						key={href}
						to={href}
						className={`md:text-lg text-md font-bold font-mono hover:opacity-100 hover:text-rose-350 hover:scale-105 transition-transform duration-200 ${
							`/${pathname.split('/')[1]}` === href
								? 'opacity-100 text-rose-350'
								: 'opacity-70'
						}`}
					>
						{label.toUpperCase()}
					</Link>
				))}
			</div>
		</div>
	) : (
		<div className="relative h-[36px]">
			<div className="fixed flex justify-between top-0 left-0 w-full bg-white z-50 h-[36px]">
				<Link
					to="/"
					className="justify-self-start"
					onClick={() => {
						if (menuOpen) {
							handleMenuClick();
						}
					}}
				>
					<img
						className="max-w-[30px] ml-2"
						src="amelia-palczynska-logo.png"
						alt="amelia-palczynska-logo"
					/>
				</Link>
				<div className="flex justify-end items-center h-full px-4">
					{menuOpen ? (
						<CloseIcon
							className="transform transition-all duration-1000 ease-in-out mt-2"
							fontSize="large"
							onClick={handleMenuClick}
						/>
					) : (
						<MenuIcon
							className="transform transition-all duration-1000 ease-in-out mt-2"
							fontSize="large"
							onClick={handleMenuClick}
						/>
					)}
				</div>
			</div>

			{menuOpen && (
				<div
					onClick={() => setMenuOpen(false)}
					className="fixed top-[35px] left-0 w-full bg-black bg-opacity-50 z-40 h-screen transition-opacity duration-500 ease-in-out"
				>
					<div
						onClick={event => event.stopPropagation()}
						className="bg-white px-4 pb-4"
					>
						<ul className="flex flex-col items-center justify-center space-y-3">
							{options.map(({ label, href }) => (
								<li key={href} className="w-full text-center">
									<Link
										onClick={() => setMenuOpen(false)}
										to={href}
										className={`text-md font-bold hover:opacity-100 hover:text-rose-350 ${
											`/${pathname.split('/')[1]}` ===
											href
												? 'opacity-100 text-rose-350'
												: 'opacity-70'
										}`}
									>
										{label}
									</Link>
								</li>
							))}
						</ul>
					</div>
				</div>
			)}
		</div>
	);
};
