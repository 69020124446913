import { useSpring, animated, easings } from '@react-spring/web';
import clsx from 'clsx';
import React from 'react';

export const Hero = () => {
	const animationStyles = useSpring({
		from: {
			opacity: 0,
			right: 400,
		},
		to: {
			opacity: 1,
			right: 0,
		},
		config: {
			duration: 1200,
			easing: easings.easeOutBounce,
			mass: 1,
			tension: 80,
			friction: 26,
		},
	});

	return (
		<section
			className={clsx(
				'flex flex-col gap-2 h-[660px] pt-[344px] overflow-hidden font-serif text-center bg-black bg-opacity-70 text-white',
				'xl:flex-row',
				'xl:bg-lg-hero-custom-gradient xl:bg-opacity-20 xl:pt-[280px]'
			)}
		>
			<animated.img
				style={animationStyles}
				className={clsx(
					'absolute top-0 right-0 -z-10 w-full h-full max-h-[696px] object-cover',
					'md:top-[64px]',
					'xl:max-w-[600px]'
				)}
				src="1.png"
				alt="amelia_palczynska_photo"
			/>
			<div className={clsx('flex flex-col gap-4', 'xl:mx-40')}>
				<h1
					className={clsx(
						'uppercase font-semibold text-3xl',
						'xl:text-5xl'
					)}
				>
					Amelia Pałczyńska
				</h1>
				<h2
					className={clsx(
						'uppercase font-light text-xl',
						'xl:text-2xl'
					)}
				>
					trener personalny
				</h2>
				<p className="flex flex-col mt-20 mx-8 xl:text-xl">
					Pomogę Ci w walce z bólem{' '}
					<span className="uppercase font-bold">oraz</span>
					Przeprowadzę przez drogę do wymarzonej sylwetki
				</p>
				<div className="w-[60%] border-b-2 border-white mx-auto mb-10 self-center justify-self-start" />
			</div>
		</section>
	);
};
